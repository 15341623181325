import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import BackgroundImage from 'gatsby-background-image';
import styled from '@emotion/styled';

const Index = ({ data }) => {
  return (
    <>
      <SEO title='Home' />
      <BackgroundImage fluid={data.allImageSharp.edges[2].node.fluid}>
        <Layout isHome>
          <StyledIndexInfo>
            <h1>coming soon...</h1>
          </StyledIndexInfo>
        </Layout>
      </BackgroundImage>
    </>
  );
};

export default Index;

export const indexQuery = graphql`
  query IndexQuery {
    allImageSharp {
      edges {
        node {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`;

const StyledIndexInfo = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    text-align: center;
    font-size: 4rem;
    font-style: italic;
    color: #fff;
  }
`;
